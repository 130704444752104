const clientID = process.env.REACT_APP_CLIENT_ID;
const tenantId = process.env.REACT_APP_TENET_ID;
const redirectURL = process.env.REACT_APP_UI_URL;
const graphEndPoint = process.env.REACT_APP_GRAPH_ENDPOINT;
const authority = process.env.REACT_APP_AUTHORITY;

export const msalConfig = {
  auth: {
    clientId: clientID ?? '',
    authority: `${authority}${tenantId}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: 'https://idtworkloadplanner.z6.web.core.windows.net/', // NOTE: This needs to be changed for all redirect. Can't use env, there was some problem with it
    redirectUri: redirectURL,
  },
  cache: {
    cacheLocation: 'memoryStorage', //'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = { //dev api
//   scopes: ['api://ac542bda-6ccb-45a3-b4bd-d9d59b070baf/task.read', 'api://ac542bda-6ccb-45a3-b4bd-d9d59b070baf/tasks.write'],
// };

export const loginRequest = { //test api
  scopes: ['api://ea8336f2-1a6b-411b-ae6f-24447d22b11f/task.read', 'api://ea8336f2-1a6b-411b-ae6f-24447d22b11f/tasks.write'],
};

// export const loginRequest = {
//   scopes: ['api://665f1823-b22e-480f-b778-0b81d154ef20/task.read', 'api://665f1823-b22e-480f-b778-0b81d154ef20/tasks.write'],
// };

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: `${graphEndPoint}/v1.0/me`,
};
