import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { IComment, ICountSummary, IMeetingMaterialSummary, IMeetingSummary } from '../../types';
import { findObjectById, makeDateReadable,makeDate } from '../../constant';
import { useAppContext } from '../../context/AppContext';
import { RdsButton, RdsIcon, RdsSpinner, RdsTag, RdsCard} from '@ramboll/rds-react';
//import './comments.scss'
import Modal from '../../constant/modal/modal'
import filesaver from 'file-saver';
import XLSX from 'sheetjs-style';

type Props = {}

function Dashboard(props: Props) {
  const navigate = useNavigate();
  const [comments, setComments] = useState<IComment[]>([]);
  const [commentIssueSummary, setCommentIssueSummary] = useState<ICountSummary[]>([]);
  const [commentIssueDateSummary, setCommentIssueDateSummary] = useState<ICountSummary[]>([]);
  const [commenterDateSummary, setCommenterDateSummary] = useState<ICountSummary[]>([]);
  const [commentMethodSummary, setCommentMethodSummary] = useState<ICountSummary[]>([]);
  const [commentStakeholderSummary, setCommentStakeholderSummary] = useState<ICountSummary[]>([]);
  const [commentActionSummary, setCommentActionSummary] = useState<ICountSummary[]>([]);
  const [meetingSummary, setMeetingSummary] = useState<IMeetingSummary[]>([]);
  const [meetingDateSummary, setMeetingDateSummary] = useState<ICountSummary[]>([]);
  const [meetingTypeSummary, setMeetingTypeSummary] = useState<ICountSummary[]>([]);
  const [meetingTimeSummary, setMeetingTimeSummary] = useState<ICountSummary[]>([]);
  const [meetingNotificationSummary, setMeetingNotificationSummary] = useState<ICountSummary[]>([]);
  const [meetingMaterialSummary, setMeetingMaterialSummary] = useState<IMeetingMaterialSummary[]>([]);
  const { meetings, issues, stakeholders, commentMethods, actions, setSelectedCommentId, users } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  useEffect(() => {
    api().dashboard.commentIssueSummary().then((c) => {
      setCommentIssueSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.commentIssueDateSummary().then((c) => {
      setCommentIssueDateSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.commenterDateSummary().then((c) => {
      setCommenterDateSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.commentMethodSummary().then((c) => {
      setCommentMethodSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.commentStakeholderSummary().then((c) => {
      setCommentStakeholderSummary(c)
    }).catch(() => {
      navigate('/error')
    })
    
    api().dashboard.commentActionSummary().then((c) => {
      setCommentActionSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingSummary().then((c) => {
      setMeetingSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingDateSummary().then((c) => {
      setMeetingDateSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingTypeSummary().then((c) => {
      setMeetingTypeSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingTimeSummary().then((c) => {
      setMeetingTimeSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingNotificationSummary().then((c) => {
      setMeetingNotificationSummary(c)
    }).catch(() => {
      navigate('/error')
    })

    api().dashboard.meetingMaterialSummary().then((c) => {
      setMeetingMaterialSummary(c)
    }).catch(() => {
      navigate('/error')
    })
  }, [])

  const onClickDiv= ((id: string) => {
    console.log("click button");
    const el = document.getElementById(id);

    // 👆
    if(el!=null){
      const elListStart: Array<string> = ["meetingDiv","commentDiv","materialDiv","exportDiv"];
      const elListHide = elListStart.filter((value) => value != id);

      elListHide.forEach(function (value) {
        console.log(value);
        const elHide = document.getElementById(value);
        if(elHide != null) elHide.style.display = 'none';
      });

      if (el.style.display === 'none') {
        el.style.display = 'block';
      } else {
        // ✅ Hides element if shown
        el.style.display = 'none';

      }
    }
    //console.log(meetingMaterialSummary);
  })

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

function formatDate(date: Date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('')
    );
  }
const dateNow = new Date();
const dateFormat = formatDate(dateNow);

var wscols = [{ width: 30, alignment:{wrapText: true} },{ width: 30 },{ width: 30 },{ width: 30 },{ width: 30 },{ width: 30 },{ width: 30 }]

var wsformat = [{ alignment:{wrapText: true} },{ alignment:{wrapText: true} },{ alignment:{wrapText: true} },{ alignment:{wrapText: true} },{ alignment:{wrapText: true} }]

//Export meeting summaries to Excel
const exportExcelMeeting = ( excelData: XLSX.WorkSheet[], sheetNames: string[], fileName: string) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.shee;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wb = {
        Sheets: { 'Meetings': excelData[0], 'Past Meetings by Type': excelData[1], 'Meetings by Notification': excelData[2]}, //'Meetings by Time of Week': excelData[1], 'Meetings by Date': excelData[2]
        SheetNames: [sheetNames[0],sheetNames[1],sheetNames[2]] //
    };
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    filesaver.saveAs(data, fileName + "_" + dateFormat + fileExtension); // 
}

//Format meeting summaries for Excel
const meetingReport = XLSX.utils.json_to_sheet(meetingSummary.map(o => new Object({'Meeting Name': o.name, 'Meeting Type': o.type,'Meeting Date': o.date,
  'Stakeholders': o.stakeholders, 'Number of Attendees': o.attendeeCount, 'Number of Comments': o.commentCount})))
meetingReport["!cols"] = wscols;
const meetingTypeReport = XLSX.utils.json_to_sheet(meetingTypeSummary.map(o => new Object({'Meeting Type': o.name, 'Number of Meetings': o.count,'Number of Attendees': o.total})))
meetingTypeReport["!cols"] = wscols;
const meetingNotificationReport = XLSX.utils.json_to_sheet(meetingNotificationSummary.map(o => new Object({'Notification Type': o.name, 'Number of Meetings': o.count,'Number of Recipients': o.total})))
meetingNotificationReport["!cols"] = wscols;
// const meetingTimeReport = XLSX.utils.json_to_sheet(meetingTimeSummary.map(o => new Object({'Time of Week': o.name, 'Number of Meetings': o.count,'Number of Attendees': o.total})))
// meetingTimeReport["!cols"] = wscols;
// const meetingDateReport = XLSX.utils.json_to_sheet(meetingDateSummary.map(o => new Object({Date: o.name, 'Number of Meetings': o.count})))
// meetingDateReport["!cols"] = wscols;

//Export comment summaries to Excel
const exportExcelComment = ( excelData: XLSX.WorkSheet[], sheetNames: string[], fileName: string) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.shee;charset=UTF-8';
    const fileExtension = '.xlsx';
    const wb = {
        Sheets: { 'Comments by Method': excelData[0], 'Comments by Stakeholder': excelData[1], 'Comments by Last Action': excelData[2], 
          'Comments by Issue': excelData[3],'Comments by Issue and Date': excelData[4],'Comments by Commenter and Date': excelData[5]},
        SheetNames: [sheetNames[0],sheetNames[1],sheetNames[2],sheetNames[3],sheetNames[4],sheetNames[5]]
    };
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    const data = new Blob([excelBuffer], {type: fileType});
    filesaver.saveAs(data, fileName + "_" + dateFormat + fileExtension);
}

//Format comment summaries for Excel
const commentMethodReport = XLSX.utils.json_to_sheet(commentMethodSummary.map(o => new Object({Method: o.name, 'Number of Comments': o.count})))
commentMethodReport["!cols"] = wscols;
const commentStakeholderReport = XLSX.utils.json_to_sheet(commentStakeholderSummary.map(o => new Object({Stakeholder: o.name, 'Number of Comments': o.count})))
commentStakeholderReport["!cols"] = wscols;
const commentActionReport = XLSX.utils.json_to_sheet(commentActionSummary.map(o => new Object({'Last Action': o.name, 'Number of Comments': o.count})))
commentActionReport["!cols"] = wscols;
const commentIssueReport = XLSX.utils.json_to_sheet(commentIssueSummary.map(o => new Object({Issue: o.name, 'Number of Comments': o.count})))
commentIssueReport["!cols"] = wscols;
const commentIssueDateReport = XLSX.utils.json_to_sheet(commentIssueDateSummary.map(o => new Object({Issue: o.name, Date: o.group, 'Number of Comments': o.count})))
commentIssueDateReport["!cols"] = wscols;
const commenterDateReport = XLSX.utils.json_to_sheet(commenterDateSummary.map(o => new Object({'Anonymized Commenter': o.group, Date: o.name, 'Number of Comments': o.count})))
commenterDateReport["!cols"] = wscols;
  
  return (
    <div style={{ marginBottom: "20px"  }} >
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>
        <h1>Reporting Dashboard</h1>
      </div>
      <div className='p-flex p-flex-wrap p-flex-row-gap'>
        <div>
          <RdsButton onClick={() => {onClickDiv("meetingDiv")}} style={{marginRight: "20px"  }}>Meetings</RdsButton>
          <RdsButton onClick={() => {onClickDiv("commentDiv")}} style={{marginRight: "20px"  }}>Comments</RdsButton>
          <RdsButton onClick={() => {onClickDiv("materialDiv")}} style={{marginRight: "20px"  }}>Meeting Materials</RdsButton>
          <RdsButton onClick={() => {onClickDiv("exportDiv")}}>Report Export</RdsButton>
        </div>
      </div>
      <br></br>
      <div className='p-flex p-flex-wrap p-flex-row-gap' id="meetingDiv" > 
        <div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
            <RdsCard variant="primary" style={{ margin: "20px" }}>
              <RdsTag>Meetings by Type (Past Meetings)</RdsTag>
              <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{float: "right"}} >
                <thead>
                  <tr>
                    <th align ="left" >Type</th>
                    <th>Number of Meetings</th>
                    <th>Number of Attendees</th>
                  </tr>
                </thead>
                {meetingTypeSummary.length > 0 ?
                  (<tbody>
                    {
                      meetingTypeSummary
                        .map((co) => (
                          <tr key={co.name}>
                            <td align ="left" style={{ width: '300px' }}>{co.name}</td>
                            <td>{co.count}</td>
                            <td>{co.total}</td>
                          </tr>
                        ))
                    }
                  </tbody>)
                  : <tbody>
                    <RdsSpinner size="medium" color="primary"></RdsSpinner>
                  </tbody>
                }
              </table>
            </RdsCard>
          </div>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}> 
          <RdsCard variant="primary" style={{ margin: "20px" }}>
            <RdsTag>Meetings By Time of Week</RdsTag>
            <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{float: "left"}} >
              <thead>
                <tr>
                  <th align ="left">Time of Week</th>
                  <th>Number of Meetings</th>
                  <th>Number of Attendees</th>
                </tr>
              </thead>
              {meetingTimeSummary.length > 0 ?
                (<tbody>
                  {
                    meetingTimeSummary
                      .map((co) => (
                        <tr key={co.name}>
                          <td align ="left" style={{ width: '300px' }}>{co.name}</td>
                          <td>{co.count}</td>
                          <td>{co.total}</td>
                        </tr>
                      ))
                  }
                </tbody>)
                : <tbody>
                  <RdsSpinner size="medium" color="primary"></RdsSpinner>
                </tbody>
              }
            </table>
          </RdsCard>
          </div>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
              <RdsCard variant="primary" style={{ margin: "20px" }}>
                <RdsTag>Meetings By Date</RdsTag>
                <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped"  >
                  <thead>
                    <tr>
                      <th align ="left">Date</th>
                      <th>Number</th>
                    </tr>
                  </thead>
                  {meetingDateSummary.length > 0 ?
                    (<tbody>
                      {
                        meetingDateSummary
                          .map((co) => (
                            <tr key={co.name}>
                              <td className="td-max" align ="left">{co.name}</td>
                              <td>{co.count}</td>
                            </tr>
                          ))
                      }
                    </tbody>)
                    : <tbody>
                      <RdsSpinner size="medium" color="primary"></RdsSpinner>
                    </tbody>
                  }
                </table>
              </RdsCard>
            </div>
            <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
            </div>
          </div>
        </div>
      </div>

      <div className='p-flex p-flex-wrap p-flex-row-gap' id="commentDiv" style={{display: "none"}}>
        <div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
          <RdsCard variant="primary" style={{ margin: "20px" }}>
            <RdsTag>Comments by Method</RdsTag>
              <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped">
                <thead>
                  <tr>
                    <th align ="left">Method</th>
                    <th>Number</th> 
                  </tr>
                </thead>
                {commentMethodSummary.length > 0 ?
                  (<tbody>
                    {
                      commentMethodSummary
                        .map((co) => (
                          <tr key={co.name}>
                            <td className="td-max" align ="left">{co.name}</td>
                            <td>{co.count}</td>
                          </tr>
                        ))
                    }
                  </tbody>)
                  : <tbody>
                    <RdsSpinner size="medium" color="primary"></RdsSpinner>
                  </tbody>
                }
            </table>
          </RdsCard>
          </div>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
          <RdsCard variant="primary" style={{ margin: "20px" }}>
            <RdsTag>Comments by Stakeholder</RdsTag>
              <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped">
                <thead>
                  <tr>
                    <th align ="left">Stakeholder</th>
                    <th>Number</th>
                  </tr>
                </thead>
                {commentStakeholderSummary.length > 0 ?
                  (<tbody>
                    {
                      commentStakeholderSummary
                        .map((co) => (
                          <tr key={co.name}>
                            <td className="td-max" align ="left">{co.name}</td>
                            <td>{co.count}</td>
                          </tr>
                        ))
                    }
                  </tbody>)
                  : <tbody>
                    <RdsSpinner size="medium" color="primary"></RdsSpinner>
                  </tbody>
                }
              </table>
          </RdsCard>
          
          </div>
          </div>
          <div style={{display: "flex", flexDirection: "row"}}>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
          <RdsCard variant="primary" style={{ margin: "20px" }}>
            <RdsTag>Comments by Issue</RdsTag>
              <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped">
                <thead>
                  <tr>
                    <th align ="left">Issue</th>
                    <th>Number</th>
                  </tr>
                </thead>
                {commentIssueSummary.length > 0 ?
                  (<tbody>
                    {
                      commentIssueSummary
                        .map((co) => (
                          <tr key={co.name}>
                            <td className="td-max" align ="left">{co.name}</td>
                            <td>{co.count}</td>
                          </tr>
                        ))
                    }
                  </tbody>)
                  : <tbody>
                    <RdsSpinner size="medium" color="primary"></RdsSpinner>
                  </tbody>
                }
            </table>
          </RdsCard>

          </div>        
          <div style={{display: "flex", flexDirection: "column", flexBasis: "50%", flexGrow: "1"}}>
          <RdsCard variant="primary" style={{ margin: "20px" }}>
            <RdsTag>Comments by Last Action</RdsTag>
              <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped">
                <thead>
                  <tr>
                    <th align ="left">Last Action</th>
                    <th>Number</th>
                  </tr>
                </thead>
                {commentActionSummary.length > 0 ?
                  (<tbody>
                    {
                      commentActionSummary
                        .map((co) => (
                          <tr key={co.name}>
                            <td className="td-max" align ="left">{co.name}</td>
                            <td>{co.count}</td>
                          </tr>
                        ))
                    }
                  </tbody>)
                  : <tbody>
                    <RdsSpinner size="medium" color="primary"></RdsSpinner>
                  </tbody>
                }
              </table>
          </RdsCard>
          </div>
          </div>
        </div>
      </div>

      <div className='p-flex p-flex-wrap p-flex-row-gap' id="materialDiv" style={{display: "none"}}>
        <div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column", flexBasis: "25%", flexGrow: "1"}}>
            {/* <main className="container__planner_main content-area"> */}
              <RdsCard variant="primary" style={{ margin: "20px" }}>
                <RdsTag>Number of Materials by Meeting</RdsTag>
                &nbsp;
                <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{float: "left"}} >
                  <thead className="thead-fixed">
                    <tr>
                      <th className="th-hor" align ="left">Meeting Name</th>
                      <th className="th-hor">Meeting Date</th>
                      <th className="th-vert">Comment Card</th>
                      <th className="th-vert">FAQ</th>
                      <th className="th-vert">Meeting Agenda</th>
                      <th className="th-vert">Meeting Audio Recording</th>
                      <th className="th-vert">Meeting Handout</th>
                      <th className="th-vert">Meeting Notes</th>
                      <th className="th-vert">Meeting Poster Board</th>
                      <th className="th-vert">Meeting Presentation</th>
                      <th className="th-vert">Meeting Talking Points</th>
                      <th className="th-vert">Meeting Video Recording</th>
                      <th className="th-vert">Notification Email</th>
                      <th className="th-vert">Notification Flyer</th>
                      <th className="th-vert">Notification Mailer</th>
                      <th className="th-vert">Technical Reference</th>
                      <th className="th-vert">Webpage</th>
                    </tr>
                  </thead>
                  {meetingMaterialSummary.length > 0 ?
                    (<tbody>
                      {
                        meetingMaterialSummary
                          .map((co) => (
                            <tr key={co.meetingId}>
                              <td className="td-vert" align ="left">{co.meetingName}</td>
                              <td className="td-vert">{makeDate(co.meetingDate)}</td>
                              <td className="td-vert">{co.commentCard}</td>
                              <td className="td-vert">{co.frequentlyAskedQuestions}</td>
                              <td className="td-vert">{co.meetingAgenda}</td>
                              <td className="td-vert">{co.meetingAudioRecording}</td>
                              <td className="td-vert">{co.meetingHandout}</td>
                              <td className="td-vert">{co.meetingNotes}</td>
                              <td className="td-vert">{co.meetingPosterBoard}</td>
                              <td className="td-vert">{co.meetingPresentation}</td>
                              <td className="td-vert">{co.meetingTalkingPoints}</td>
                              <td className="td-vert">{co.meetingVideoRecording}</td>
                              <td className="td-vert">{co.notificationEmail}</td>
                              <td className="td-vert">{co.notificationFlyer}</td>
                              <td className="td-vert">{co.notificationMailer}</td>
                              <td className="td-vert">{co.technicalReference}</td>
                              <td className="td-vert">{co.webpage}</td> 
                            </tr>
                          ))
                      }
                    </tbody>)
                    : <tbody>
                      <RdsSpinner size="medium" color="primary"></RdsSpinner>
                    </tbody>
                  }
                </table>
                </RdsCard>
                
              {/* </main> */}

          </div>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "33%", flexGrow: "1"}}></div>
        </div>
      </div>
    </div>

      <div className='p-flex p-flex-wrap p-flex-row-gap' id="exportDiv" style={{display: "none"}}>
        <div>
          <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{display: "flex", flexDirection: "column", flexBasis: "10%", flexGrow: "1"}}>
              <RdsCard variant="primary" style={{ margin: "20px" }}>
                  <RdsTag>Export Reports to Excel</RdsTag>
                  &nbsp;
                <RdsButton variant="secondary" onClick={(e) => exportExcelMeeting([meetingReport, meetingTypeReport, meetingNotificationReport], //meetingTimeReport,meetingDateReport
                    ["Meetings","Past Meetings by Type", "Meetings by Notification"], 'ClecoCommentTool_MeetingReport')} style={{ margin: "10px" }}> 
                        Meeting Report
                </RdsButton>
                <br/>
                <RdsButton variant="secondary" onClick={(e) => exportExcelComment([commentMethodReport, commentStakeholderReport, commentActionReport, commentIssueReport, commentIssueDateReport,commenterDateReport],
                    ["Comments by Method","Comments by Stakeholder","Comments by Last Action","Comments by Issue","Comments by Issue and Date","Comments by Commenter and Date"], 'ClecoCommentTool_CommentReport')} style={{ margin: "10px" }}>
                        Comment Report
                </RdsButton>
              </RdsCard>
            </div>
            <div style={{display: "flex", flexDirection: "column", flexBasis: "10%", flexGrow: "1"}}>

            </div>
          </div>
    </div>
  </div>
  </div>
  )
}

export default Dashboard

{/* //export const MyComponent = () => <Accordion items={items} duration={300} multiple={true} />; */}
