import React, { useEffect, useState } from 'react'
import { dummyComment, makeDateReadable, makeDateTimeReadable, makeOptionsForInput, makeDate, makeTime, dummyTableFilters, dummyPagination, pageOptions} from '../../constant'
import api from '../../api'
import { useNavigate } from 'react-router-dom'
import { IComment, IMeeting, TableFilters, Pagination, OptionObjectType} from '../../types' //, IUser
import { RdsButton, RdsIcon, RdsSpinner, RdsSelect } from '@ramboll/rds-react'
import { useAppContext } from '../../context/AppContext'
import { findObjectById } from '../../constant';
import Select from 'react-select';
import { filterBar, filterBase } from './filter';
import './meetings.scss'
import MeetingMaterials from '../material/materialForm'
import Modal from '../../constant/modal/modal'
import { DropDown } from '../../constant/components/dropdown/DropDown';
import { DatePickerWrapperFilter } from './components/DatePickerFilter';
import { TimePickerWrapperFilter } from './components/TimePickerFilter';

type Props = {}

const Meetings = (props: Props) => {
  const navigate = useNavigate();
  const [meetings, setMeetings] = useState<IMeeting[]>([])
  const [originalList, setOriginalList] = useState<IMeeting[]>();
  const { meetingTypes, stakeholders, notificationMethods, materials } = useAppContext(); //, users 
  const [stakeholderOptions, setStakeholderOptions] = useState<OptionObjectType[]>([])
  const [notificationOptions, setNotificationOptions] = useState<OptionObjectType[]>([])
  const [materialOptions, setMaterialOptions] = useState<OptionObjectType[]>([])
  const [meetingTypeOptions, setMeetingTypeOptions] = useState<OptionObjectType[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [spinner, setSpinner] = useState(true);
  const [deleteId, setDeleteId] = useState(0)
  const [tableFilter, setTableFilter] = useState<TableFilters>(dummyTableFilters);
  const [pagination, setPagination] = useState<Pagination>(dummyPagination);
  const [buttonList, setButtonList] = useState<any[]>([]);

  const { issues, setSelectedMeetingId, users } = useAppContext()

  useEffect(() => {
    setMeetingTypeOptions(makeOptionsForInput(meetingTypes, 'name', 'id'))
    setStakeholderOptions(makeOptionsForInput(stakeholders, 'name', 'id'))
    setNotificationOptions(makeOptionsForInput(notificationMethods, 'name', 'id'))
    setMaterialOptions(makeOptionsForInput(materials, 'name', 'id'))
  }, [stakeholders, notificationMethods, materials])

  useEffect(() => {
    api().meeting.get().then((mo) => {
      console.log('Getting all the meetings')
      setSelectedMeetingId(0)
      setMeetings(mo)
      setOriginalList(mo);
      runningListThroughPagination(mo, mo);
      setSpinner(false);
    }).catch(() => {
      navigate('/error')
    })
  }, [])

  const runningListThroughPagination = (
    workList: IMeeting[],
    original: IMeeting[],
  ) => {
    // CLoning the workLoadPlanAll
    const clonedWorkLoadPlanAll: IMeeting[] = JSON
      .parse(JSON.stringify(workList));

    // checking if filter is on or not and which list to select
    // When the filter is not on, we will use the original list
    // When filter is on, we will use the clonedWorkLoadPlanAll
    // This will make sure that we will have pagination when using
    // filters.
    const isFilterOn = (((Object.keys(tableFilter.equals)).length !== 0)
      || ((Object.keys(tableFilter.greater)).length !== 0)
      || ((Object.keys(tableFilter.includes)).length !== 0)
      || ((Object.keys(tableFilter.lesser)).length !== 0)) ? 1 : 0;
    const listSelected = isFilterOn ? clonedWorkLoadPlanAll : original;

    // Calculating the amount of pages we can get due to the limit
    // Calculation is dataLength / pagination limit + (if we have remainders) 1 (else) 0
    const dataLength = listSelected?.length;
    const numberOfPages = (Math.floor((dataLength ?? 0) / pagination.limit))
      + (((dataLength ?? 0) % pagination.limit) > 0
        ? 1
        : 0);

    // if the filter is on then the start will be from the first page
    // set start can be according to the page selected and limit
    // end is just (selectedPage +1) * limit
    const start = isFilterOn ? 0 : pagination.selectedPage * pagination.limit;
    const end = (pagination.selectedPage + 1) * pagination.limit;
    setPagination({
      ...pagination,
      numberOfPages,
      start,
      end,
    });
    // slicing base on the start and end value
    setMeetings([...listSelected.slice(start, end)]);
  };
  /**
   * This function just sets the pagination.selectedPage
   * @param {number} pageNumber
   */
  const pageChange = (pageNumber: number) => {
    setPagination({
      ...pagination,
      selectedPage: pageNumber,
    });
  };
  /**
   * This function after getting triggered uses the value of pagination.numberOfPages
   * To create a list of object that will be used to create buttons for pagination
   */
  const paginationRdsSelect = () => {
    if (pagination) {
      const newButtonList = [];
      for (let index = 0; index < pagination.numberOfPages; index += 1) {
        newButtonList.push({ label: index + 1, value: index });
      }
      setButtonList(newButtonList);
    }
  };

  const returnSelectedValueForSelectList = (attribute: any, OptionListToSearchFrom: OptionObjectType[]) => {
    if (attribute) {
      const value = []
      for (let index = 0; index < attribute.length; index++) {
        const element = attribute[index];
        value.push(OptionListToSearchFrom.find((c) => c.value === element))
      }
      return value
    }
    return []
  }

  function sortBasedOnDates(attribute: 'date') {
    setMeetings((t) => (
      t.sort((a, b) => {
        return new Date(a[attribute]).valueOf() - new Date(b[attribute]).valueOf()
      })
    ))
  }

  useEffect(() => {
    if (originalList) {
      // 1. create a clone of the original state stored
      let cloneOriginal: IMeeting[] = JSON.parse(JSON.stringify(originalList));
      // 2. Since its nested, let get the first
      // keys which are the operators (includes, equal, less, more)
      const operators: any = Object.keys(tableFilter);
      // 3. Start the loop for the top object
      for (let index = 0; index < operators.length; index += 1) {
        // 4. Get all columnNames and loop it
        const operator: keyof TableFilters = operators[index];
        const columnNames: any = Object.keys(tableFilter[operator]);
        for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
          // 5. Lets check which operator it is and change
          // the cloneOriginal according to the operator
          // 6. The cloneOriginal continues till the end of the loop
          const columnName: keyof IMeeting = columnNames[cidx];
          const valueFromColumn = (tableFilter[operator][columnName]);
          if (operator === 'includes') {

            // if (columnName === 'response') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  console.log("logical: " + (wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())));
                  return wpe[columnName]?.toString().toLowerCase().includes(valueFromColumn.toLowerCase())
                }
                return false;
              });
            // } else {
            // cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
            //   .toString().includes(valueFromColumn));
            // }
          } else if (operator === 'equals') {
            if (columnName === 'typeId' ) {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return valueFromColumn.includes(wpe[columnName]);
                }
                return false;
              });
            } else if (columnName === 'stakeholders' || columnName === 'notificationIds' || columnName === 'materials') {
              cloneOriginal = cloneOriginal?.filter((wpe) => {
                if (wpe[columnName] !== undefined) {
                  return wpe[columnName].some(a => valueFromColumn.includes(a));
                }
                return false;
              });
            } else if (columnName === 'date') {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                return wpe[columnName].split('T')[0] === valueFromColumn
              });
            } else {
              cloneOriginal = cloneOriginal?.filter((wpe: any) => wpe[columnName]
              === valueFromColumn);
            } 
          } else if (operator === 'greater' ) {
            if (columnName === 'date') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  return new Date(valueFromColumn) <= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else if (columnName === 'time') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  var dValue = new Date().toISOString().substring(0, 11);
                  var timeFormat = wpe[columnName].split('T')[1];
                  return dValue + timeFormat! >= valueFromColumn;
                  //return wpe[columnName]! >= valueFromColumn;
                  //return new Date(valueFromColumn) <= new Date(wpe[columnName].split('T')[0])
                }
                return false;
              });
            } else {
            // cloneOriginal = cloneOriginal?.filter((wpe) => wpe[columnName]
            //   > valueFromColumn);
            cloneOriginal = cloneOriginal?.filter((wpe) => {
              if (columnName === 'attendeeCount' && wpe[columnName] != null) {
                return wpe[columnName]! >= valueFromColumn;
              }
              return false;
            });
            }
          } else if (operator === 'lesser') {
            if (columnName === 'date') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  return new Date(valueFromColumn) >= new Date(wpe[columnName].split('T')[0])
                }
              });
            } else if (columnName === 'time') {

              cloneOriginal = cloneOriginal?.filter((wpe: any) => {
                if (wpe[columnName] !== null) {
                  var dValue = new Date().toISOString().substring(0, 11);
                  var timeFormat = wpe[columnName].split('T')[1];
                  return dValue + timeFormat! <= valueFromColumn;
                }
                return false;
              });
            } else {
            cloneOriginal = cloneOriginal?.filter((wpe) => {
              if (columnName === 'attendeeCount' && wpe[columnName] != null) {
                return wpe[columnName]! <= valueFromColumn;
              }
              return false;
            });
          }
        }
      }
    }
      // 7. Set cloneOriginal to workLoadPlanAll
      runningListThroughPagination(cloneOriginal, originalList);
    }
  }, [tableFilter]);

  useEffect(() => {
    runningListThroughPagination(meetings ?? [], originalList ?? []);
  }, [
    pagination.limit,
    pagination.start,
    pagination.end,
    pagination.numberOfPages,
    pagination.selectedPage,
  ]);
  // useEffect when numberOfPagesChanges
  useEffect(() => {
    paginationRdsSelect();
  }, [pagination.numberOfPages]);

  const filterFunctionInclude = (value: any, columnName: keyof IMeeting) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    cloneFilter.includes[columnName] = value;
    setTableFilter({ ...cloneFilter });
  };

  
  const filterFunctionGreater = (value: any, columnName: keyof IComment) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    cloneFilter.greater[columnName] = value;
    setTableFilter({ ...cloneFilter });
  };

  const filterFunctionLesser = (value: any, columnName: keyof IComment) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    cloneFilter.lesser[columnName] = value;
    setTableFilter({ ...cloneFilter });
  };


  const filterFunctionEqual = (
    value: any,
    columnName: keyof IMeeting,
    state: OptionObjectType[],
    isMulti: boolean = false
  ) => {
    // No direct way of assigning values to nested objects
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    if (!isMulti) {
      const valueFromDropDownList = state.find((c) => c.value === Number(value));
      cloneFilter.equals[columnName] = valueFromDropDownList?.value;
      setTableFilter({ ...cloneFilter });
    }
    else {
      cloneFilter.equals[columnName] = (value);
      setTableFilter({ ...cloneFilter });
    }
  };

  // const filterFunctionLessThan = (
  //   value: number,
  //   columnName: keyof IMeeting
  // ) => {
  //   // No direct way of assigning values to nested objects
  //   const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
  //     console.log('less than');
  //     cloneFilter.equals[columnName] <= (value);
  //     setTableFilter({ ...cloneFilter });
  //   };

  // const filterFunctionGreaterThan = (
  //   value: number,
  //   columnName: keyof IMeeting
  // ) => {
  //   // No direct way of assigning values to nested objects
  //   const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
  //     console.log('greater than');
  //     cloneFilter.equals[columnName] >= (value);
  //     setTableFilter({ ...cloneFilter });
  //   };

    /**
   * function for button reset, which deletes all the columnName from
   * all the operators in tableFilter
   */
  const reset = ((inputColName = '') => {
    // 1. Clone the tableFilter
    const cloneFilter: TableFilters = JSON.parse(JSON.stringify(tableFilter));
    // 2. Get all operators to loop
    const operators: any = Object.keys(cloneFilter);
    // 3. Start the loop for the top object
    for (let index = 0; index < operators.length; index += 1) {
      const operator: keyof TableFilters = operators[index];
      const columnNames: any = Object.keys(cloneFilter[operator]);
      // 4. Start the loop for the with the columnNames
      for (let cidx = 0; cidx < columnNames.length; cidx += 1) {
        const columnName: keyof IMeeting = columnNames[cidx];
        // 5. Delete the specific columnName from the object if no inputColName supplied
        if (inputColName === '') {
          delete cloneFilter[operator][columnName];
          // 6. Else only delete the inputColName
        } else if (inputColName === columnName) {
          delete cloneFilter[operator][columnName];
        }
      }
    }
    setTableFilter({ ...cloneFilter });
  });

  /**
   * This function is set for setting selectedPage for two arrow between pagination buttons
   * @param {string} position - use "first" for the left arrow and "last" for the right arrow
   */
  const onArrowClick = (position: string) => {
    setPagination({
      ...pagination,
      selectedPage: position === 'first' ? 0 : pagination.numberOfPages - 1,
    });
  };

  // const _grid = React.useRef<any>();

  // const _export = React.useRef<ExcelExport | null>(null);
  
  // const excelExport = () => {
  //   if (_export.current !== null) {
  //     _export.current.save();
  //   }
  // };

  const onConfirmDelete = (() => {
    api().meeting.deleteEventById(deleteId).then(() => {
      // toast.success("Project Deleted", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
      setMeetings((sm: IMeeting[]) => [
        ...sm.filter((s) => s.id !== deleteId)
      ])
    })
    setModalOpen(false);
  })

  // const onClickDelete = ((id: number) => {
  //   console.log("Delete Button");
  // })

  const onClickDelete = ((meetingId: number) => {
    console.log("click delete");
    onDialogToggled({ detail: modalOpen });
    setDeleteId(meetingId);
    console.log(deleteId);
  })

  const onDialogToggled = ((e: { detail: boolean }) => {
    setModalOpen(!e.detail)
  })

  return (
    <div>
      <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>
        <h1>Meetings</h1>
        <div>
          <RdsButton variant="secondary" onClick={() => reset()} style={{ marginRight: "10px" }}>Reset Filter</RdsButton>
          <RdsButton onClick={() => { navigate('/meetings/create') }}>Create Meeting Record</RdsButton>
        </div>
      </div>

      <Modal
        open={modalOpen}
        variant={"danger"}
        size={"small"}
        label={"Delete Meeting?"}
        onDialogToggled={onDialogToggled}
      >
        <div className='p-flex p-flex-column'>
          {/* {label} */}
          <div slot="footer" className="r-flex r-gap-xs">
            <RdsButton variant="secondary" onClick={() => onDialogToggled({ detail: true })} >Cancel</RdsButton>
            <RdsButton variant="danger" onClick={() => {onConfirmDelete()}}>Delete</RdsButton>
          </div>
        </div>
      </Modal>

      <main className="container__planner_main content-area">
        <table className="r-table r-text-center r-table--hover r-table--card r-table--small r-table--stripped" style={{ minHeight: '450px' }}>
          <thead className="thead-fixed">
            <tr>
            <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'name', 'Name')}
                    </>,
                    'Meeting Name',
                    tableFilter?.includes?.name,
                  )
                }
              </th>
              {/* <th align ="left">Name</th> */}
              <th>
                      {
                        filterBase(
                          <>
                          <div style={{ minWidth: '200px' }}>
                            <Select
                              options={meetingTypeOptions}
                              value={returnSelectedValueForSelectList(tableFilter?.equals?.typeId, meetingTypeOptions)}
                              isSearchable
                              isMulti={true}
                              onChange={(events) => {
                                if (events.length > 0) {
                                  const selected = []
                                  for (let index = 0; index < events.length; index++) {
                                    const element = events[index];
                                    if (element) {
                                      selected.push(Number(element.value))
                                    }
                                  }
                                  filterFunctionEqual(selected, 'typeId', meetingTypeOptions, true);
                                }
                                else {
                                  reset('typeId')
                                }
                              }}
                            />
                          </div>
                          {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                          <RdsButton className='p-margin-top-20' onClick={() => reset('typeId')}>Reset</RdsButton>
                        </>,
                        'Type',
                        tableFilter?.equals?.typeId,
                        )
                      }
              </th>
              {/* <th>Type</th> */}
              <th>
                {
                  DropDown(
                    <div>
                      <label>Date</label>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="FROM"
                          attribute='date'
                          defaultValue={tableFilter?.greater?.date}
                          condition='greater'
                        />
                      </div>
                      <div className='p-padding-bottom-10'>
                        <DatePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="TO"
                          attribute='date'
                          defaultValue={tableFilter?.lesser?.date}
                          condition='lesser'
                        />
                      </div>
                      <div>
                        {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                        <RdsButton onClick={() => reset('date')}>Reset</RdsButton>
                        {/* <RdsButton className='p-margin-left-10' onClick={() => sortBasedOnDates('date')}>Sort</RdsButton> */}
                      </div>
                    </div>,
                    'Date',
                    tableFilter?.greater?.date,
                    tableFilter?.lesser?.date,
                  )
                }
              </th>
              {/* <th>Date</th> */}
              {/* <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionGreater, 'time', 'From')}
                      {filterBar(filterFunctionLesser, 'time', 'To')}
                      <RdsButton className='p-margin-top-20' onClick={() => reset('time')}>Reset</RdsButton>
                    </>,
                    'Time',
                    tableFilter?.greater?.time,
                  )
                }
              </th> */}
              <th>
                {
                  DropDown(
                    <div>
                      <label>Time</label>
                      <div className='p-padding-bottom-10'>
                        <TimePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="FROM"
                          attribute='time'
                          defaultValue={tableFilter?.greater?.time}
                          condition='greater'
                        />
                      </div>
                      <div className='p-padding-bottom-10'>
                        <TimePickerWrapperFilter
                          tableFilter={tableFilter}
                          setTableFilter={setTableFilter}
                          name="TO"
                          attribute='time'
                          defaultValue={tableFilter?.lesser?.time}
                          condition='lesser'
                        />
                      </div>
                      <div>
                        {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                        <RdsButton onClick={() => reset('time')}>Reset</RdsButton>
                        {/* <RdsButton className='p-margin-left-10' onClick={() => sortBasedOnDates('date')}>Sort</RdsButton> */}
                      </div>
                    </div>,
                    'Time',
                    tableFilter?.greater?.time,
                    tableFilter?.lesser?.time,
                  )
                }
              </th>
              {/* <th>Time</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionInclude, 'location', 'Location')}
                    </>,
                    'Location',
                    tableFilter?.includes?.location,
                  )
                }
              </th>
              {/* <th>Location</th> */}
              <th>
                {
                  filterBase(
                    <>
                    <div style={{ minWidth: '200px' }}>
                      <Select
                        options={stakeholderOptions}
                        value={returnSelectedValueForSelectList(tableFilter?.equals?.stakeholders, stakeholderOptions)}
                        isSearchable
                        isMulti={true}
                        onChange={(events) => {
                          if (events.length > 0) {
                            const selected = []
                            for (let index = 0; index < events.length; index++) {
                              const element = events[index];
                              if (element) {
                                selected.push(Number(element.value))
                              }
                            }
                            filterFunctionEqual(selected, 'stakeholders', stakeholderOptions, true);
                          }
                          else {
                            reset('stakeholders')
                          }
                        }}
                      />
                    </div>
                    {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                    <RdsButton className='p-margin-top-20' onClick={() => reset('stakeholders')}>Reset</RdsButton>
                  </>,
                  'Stakeholders',
                  tableFilter?.equals?.stakeholders,
                  )
                }
              </th>
              {/* <th>Stakeholders</th> */}
              <th>
                {
                  filterBase(
                    <>
                      {filterBar(filterFunctionGreater, 'attendeeCount', 'From')}
                      {filterBar(filterFunctionLesser, 'attendeeCount', 'To')}
                      <RdsButton className='p-margin-top-20' onClick={() => reset('attendeeCount')}>Reset</RdsButton>
                    </>,
                    'Number of Attendees',
                    tableFilter?.greater?.attendeeCount,
                  )
                }
              </th>
              {/* <th>Number of Attendees</th> */}
              <th>
                {
                  filterBase(
                    <>
                    <div style={{ minWidth: '200px' }}>
                      <Select
                        options={notificationOptions}
                        value={returnSelectedValueForSelectList(tableFilter?.equals?.notificationIds, notificationOptions)}
                        isSearchable
                        isMulti={true}
                        onChange={(events) => {
                          if (events.length > 0) {
                            const selected = []
                            for (let index = 0; index < events.length; index++) {
                              const element = events[index];
                              if (element) {
                                selected.push(Number(element.value))
                              }
                            }
                            filterFunctionEqual(selected, 'notificationIds', notificationOptions, true);
                          }
                          else {
                            reset('notificationIds')
                          }
                        }}
                      />
                    </div>
                    {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                    <RdsButton className='p-margin-top-20' onClick={() => reset('notificationIds')}>Reset</RdsButton>
                  </>,
                  'Notifications',
                  tableFilter?.equals?.notificationIds,
                  )
                }
              </th>
              {/* <th>Notifications</th> */}
              {/* <th>Notification Method</th>
              <th>Notification Distribution Date</th>
              <th>Notification Recipient Count</th> */}
              <th>
                {
                  filterBase(
                    <>
                    <div style={{ minWidth: '200px' }}>
                      <Select
                        options={materialOptions}
                        value={returnSelectedValueForSelectList(tableFilter?.equals?.materials, materialOptions)}
                        isSearchable
                        isMulti={true}
                        onChange={(events) => {
                          if (events.length > 0) {
                            const selected = []
                            for (let index = 0; index < events.length; index++) {
                              const element = events[index];
                              if (element) {
                                selected.push(Number(element.value))
                              }
                            }
                            filterFunctionEqual(selected, 'materials', materialOptions, true);
                          }
                          else {
                            reset('materials')
                          }
                        }}
                      />
                    </div>
                    {/* <RdsButton onClick={() => onClickFilterButton()} className='p-margin-right-10'>Filter</RdsButton> */}
                    <RdsButton className='p-margin-top-20' onClick={() => reset('materials')}>Reset</RdsButton>
                  </>,
                  'Materials',
                  tableFilter?.equals?.materials,
                  )
                }
              </th>
              {/* <th>Meeting Materials</th> */}
              {/* <th>Last Updated</th>
              <th>Last Updated By</th> */}
              <th></th>
            </tr>
          </thead>
          {meetings.length > 0 ?
            (<tbody>
              {
                meetings
                  .sort((a, b) => a.id - b.id)
                  .map((me) => (
                    <tr key={me.id + me.date}>
                      <td align ="left">{me.name}</td>
                      <td>{findObjectById(meetingTypes, me.typeId) ? findObjectById(meetingTypes, me.typeId).name : ""}</td>
                      <td>{makeDate(me.date)}</td>
                      <td>{makeTime(me.time)}</td>
                      {/* <td>{me.date}</td> */}
                      <td>{me.location}</td>
                      {me.stakeholders && me.stakeholders.length > 0 ? 
                        (<td>
                          {me.stakeholders.map((stakeholder, index) => (
                            <div key={`${stakeholder}-${index}`}>
                              {findObjectById(stakeholders, stakeholder) ?
                              findObjectById(stakeholders, stakeholder).name : ""}
                            </div>
                          ))}
                        </td>)
                        : 
                        (<td></td>)
                      }
                      {/* <td>-placeholder-</td> */}
                      <td>{me.attendeeCount}</td>
                      {me.notificationIds && me.notificationIds.length > 0 ? 
                        (<td>
                          {me.notificationIds.map((notificationMethodId, index) => (
                            <div key={`${notificationMethodId}-${index}`}>
                              {findObjectById(notificationMethods, notificationMethodId) ?
                              findObjectById(notificationMethods, notificationMethodId).name : ""}
                            </div>
                          ))}
                        </td>)
                        : 
                        (<td></td>)
                      }
                      {me.materials && me.materials.length > 0 ? 
                        (<td>
                          {me.materials.map((material, index) => (
                            <div key={`${material}-${index}`}>
                              {findObjectById(materials, material) ?
                              findObjectById(materials, material).name + (findObjectById(materials, material).version !== null && findObjectById(materials, material).version !== "" ? (" (version " + findObjectById(materials, material).version + ")") : "") : ""}
                            </div>
                          ))}
                        </td>)
                        : 
                        (<td></td>)
                      }
                      {/* <td>-placeholder-</td> */}
                      {/* <td>{findObjectById(notificationMethods, me.notificationMethodId) ? findObjectById(notificationMethods, me.notificationMethodId).name : ""}</td>
                      <td>{makeDateReadable(me.notificationDistributionDate)}</td>
                      <td>{me.notificationRecipientCount}</td> */}
                      {/* <td>{makeDateReadable(me.timestamp)}</td>
                      <td>{findObjectById(users, me.userId) ? findObjectById(users, me.userId).email : ""}</td> */}
                      {/* <td>{me.userId}</td> */}
                      <td>
                        <div>
                          <div className="tooltip p-padding-top-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="primary"
                                size="small"
                                onClick={() => {
                                  //navigate('/projects/create'); setSelectedProjectId(po.id)
                                  console.log(me.id)
                                  setSelectedMeetingId(me.id)
                                  navigate('/meetings/create')
                                }}
                              >
                                <RdsIcon
                                  name="pencil-fill"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">Meeting Edit</span>
                            </div>
                          </div>
                          <div className="tooltip p-padding-top-10 p-padding-left-10">
                            <div className="tooltip">
                              <RdsButton
                                slot="trigger"
                                variant="danger"
                                size="small"
                                onClick={() => onClickDelete(me.id)} //me.id
                              >
                                <RdsIcon
                                  name="trash"
                                  size="small"
                                />
                              </RdsButton>
                              <span className="tooltiptext">Delete Meeting</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>
                  ))
              }
            </tbody>)
            : <tbody>
              {spinner ? <RdsSpinner size="medium" color="primary">Loading Meetings</RdsSpinner> : "No entries"}
            </tbody>
          }
        </table>
      </main>


         <div className='p-flex p-mainaxis-space-between' style={{ marginBottom: "20px" }}>

          <div style={{display: "flex", flexDirection: "row"}}>
              <RdsButton size="medium" className="item" onClick={() => onArrowClick('first')}>&laquo;</RdsButton>
              &nbsp;
              {buttonList.map((bu) => ( //reverse().
                <RdsButton size="medium" key={`rds-button-${bu.value}`} className="item" variant={(pagination.selectedPage !== undefined && Number(pagination.selectedPage) === bu.value) ? 'secondary' : 'primary'} onClick={() => pageChange(bu.value)}>{bu.label}</RdsButton>
              ))}
              &nbsp;
              <RdsButton size="medium" className="item" onClick={() => onArrowClick('last')}>&raquo;</RdsButton>
              &nbsp;
              <RdsSelect
              className="rds-select-custom"
              options={JSON.stringify(pageOptions)}
              value={pagination.limit}
              onInput={(e: any) => {
                setPagination({
                  ...pagination,
                  limit: Number(e.target.value),
                  selectedPage: 0,
                });
              }}
              style={{height:"20px"}}
              />


            
          </div>
          {/* <div className="paginationInfo">
            {`Showing items 
          ${(pagination.start + comments.length)} of 
          ${(((Object.keys(tableFilter.equals)).length !== 0)
                || ((Object.keys(tableFilter.greater)).length !== 0)
                || ((Object.keys(tableFilter.includes)).length !== 0)
                || ((Object.keys(tableFilter.lesser)).length !== 0)) ? (comments.length) : originalList.length}`}
          </div> */}
          </div>
    </div>
  )
}

export default Meetings